.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* display: none; */
    display: block;
    z-index: 10;
    background-color: white;
    border: 4px solid #f79663;
    border-radius: 22px;
  }
  .contentBox{
    position: relative;
    width: 720px;
    height: 600px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    box-shadow: 0 5px 5px rgba(0,0,o,0.1);
  }
  .contentBox .imgBx{
  position: relative;
  width: 336px;
  height: 595px;
  /* //margin-top: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .contentBox .imgBx::before{
    content: '';
    position: absolute;
    height: 200px;
    width: 200px;
    background: #e7ffe0;
    border-radius: 50%;
  }
  
  .contentBox .imgBx img{
    position: relative;
    max-width:300px;
    z-index: 1;
  }
  
  .contentBox .content{
    position: relative;
    width: 415px;
    height: 600px;
    justify-content: center;
    word-break: break-all;
    align-items: center;
    display: flex;
  }
  .contentBox .content h3{
  color: #333;
  Line-height: 1;
  font-weight: 300; 
  font-size: 2em;
  }
  
  .contentBox.content h2{
  font-size: 4em;
  color: #ff4d54;
  }
  .contentBox .content h2 span{
  
  color:#333;
  font-size: 0.75em;
  text-transform: uppercase;
  }
  
  .contentBox .content p{
  font-weight: 300;
  
  }
  
  .contentBox .content a{
  display:inline-block;
  padding: 10px 20px;
  /* background: #ff4d54; */
  color: black;
  margin-top: 15px;
  text-decoration: none;
  border-radius: 10px;
  }
  .close1{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    /* background: url('../img/close.png'); */
    background-size: cover;
    z-index: 10;
    cursor: pointer;
  }
  @media(max-width: 767px){

  .login-box{
    margin-left: -103px;
    margin-top: 24px;
  }
  .contentBox{
  width: 330px;
  height: auto;
  flex-direction: column;
  }
  
  .contentBox .imgBx
  {
  height: 200px;
  display: none;
  transform: translateY(-50px);
  }
  .contentBox .imgBx::before{
  background: #fff;
  }
  
  .contentBox .content{
  height: auto;
  text-align: center;
  padding: 20px;
  padding-top: 0;
  }
  .close{
    top: 10px;
    right: 10px;
  }
  
  }
  .contentBox .content h3{
    font-weight: bold;
  }
  .contentBox .content p{
color: black;
font-size: large;
  }

  .login-box {
    /* position: absolute;
    top: 50%;

    left: 50%;
    width: 400px; */
    padding: 40px;
    /* margin-top: 190px; */
    /* transform: translate(-50%, -50%); */
    /* background: rgba(0,0,0,.5); */
    box-sizing: border-box;
    /* box-shadow: 0 15px 25px rgba(0,0,0,.6); */
    border-radius: 10px;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: black;
    text-align: center;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color:black;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top:0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
  }
  /* .user-box label{
    top: -20px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
  }
   */
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: black;
    font-size: 12px;
  }
  .login-box .user-box textarea:focus ~ label,
  .login-box .user-box textarea:valid ~ label {
    top: -20px;
    left: 0;
    color: black;
    font-size: 12px;
  }
  .login-box .user-box select:focus ~ label,
  .login-box .user-box select:valid ~ label {
    top: -20px;
    left: 0;
    color: black;
    font-size: 12px;
  }
  .login-box form button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px
  }
  
  .login-box button:hover {
    background: #f79663;
    color: black;
    border-radius: 5px;
    box-shadow: 0 0 5px #00abb2,
                0 0 25px #00abb2,
                0 0 50px #00abb2,
                0 0 100px #00abb2;
  }
  
  .login-box button span {
    position: absolute;
    display: block;
  }
  
  .login-box button span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, black);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }
  
  .login-box button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, black);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .login-box button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, black);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .login-box button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, black);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  .sel{
    margin-top: 16px;
    width: 108px;
    height: 30px;
    border-radius: 8px;
  }
  .textt{
    margin-top: 13px;
    height: 75px;
    width: 200px;
  }
  .wrapper-1{
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }
  h1{
      font-family: 'Kaushan Script', cursive;
    font-size:4em;
    letter-spacing:3px;
    color:#5892FF ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }